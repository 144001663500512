<template>
  <div>
    <div style="margin: 0 0 16px 8px">
      <a-breadcrumb separator="»">
        <a-breadcrumb-item>
          <span style="font-size: 16px;"><b>Quản lý ngày công</b></span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a-icon type="table" href="" />
          <span> Chấm công</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link to="/admin/thong-ke-cham-cong.html">
            <a-icon type="table" style="color: #1890ff" />
            <span style="color: #1890ff">Thống kê</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/admin/cong-nhat/tong-cong.html">
            <a-icon type="table" />
            <span>Tổng công</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/admin/bao-cao/bieu-do-tong-cong.html">
            <a-icon type="line-chart" />
            <span>Biểu đồ lượng khách</span>
          </router-link>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-card>
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :xs="24" :sm="4" :md="6" :lg="4" :xl="4">
            <a-date-picker
              @change="onChangeDate"
              :format="'DD/MM/YYYY'"
              style="width: 100%"
              placeholder="Lựa chọn ngày"
              :defaultValue="moment()"
            />
          </a-col>
          <a-col :xs="24" :sm="4" :md="6" :lg="4" :xl="4">
            <a-select
              placeholder="Chọn tất cả salon"
              default-value="0"
              :allowClear="true"
              v-model="queryParam.salonId"
              :filterOption="filterOptionSalon"
              showSearch
            >
              <a-select-option value="0">Chọn tất cả salon</a-select-option>
              <a-select-option v-for="salon in user.listSalon" :key="salon.id">
                {{ salon.salonName }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :xs="24" :sm="4" :md="6" :lg="16" :xl="8">
            <a-button type="primary" @click="getData">Xem danh sách</a-button>
            <a-button type="default" style="margin-left: 10px" @click="resetFilter">Reset Filter</a-button>
          </a-col>
        </a-row>
      </a-form>
      <div style="margin-top: 30px">
        <p>
          <span>
            <a-icon type="ordered-list" style="margin-right: 10px" /><strong
              >Danh sách số lương Stylist/Skinner được chấm công của từng Salon</strong
            >
          </span>
        </p>
        <c-table
          :columns="columns"
          :dataSource="dataSource"
          :loading="loading"
          :rowKey="(record, index) => record.salonId"
        ></c-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParam.page"
          :limit.sync="queryParam.limit"
          @pagination="getData"
        />
      </div>
    </a-card>
  </div>
</template>

<script>
import { getFactory } from '@/api'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Pagination from '@/components/Pagination'
const TimekeepingReportRepository = getFactory('timekeepingReport')
import CTable from './components/CustomTableReport'

export default {
  computed: {
    ...mapGetters(['user']),
    columns: function() {
      let date = this.moment()
      if (this.workDate) {
        date = this.workDate.clone()
      }
      const columns = [
        {
          title: 'Salon',
          dataIndex: 'salonName'
        },
        {
          title: 'Bộ phận',
          children: [
            {
              title: 'Stylist',
              dataIndex: 'totalStylist',
              scopedSlots: { customRender: 'totalStylist' }
            },
            {
              title: 'Skinner',
              dataIndex: 'totalSkinner'
            },
            {
              title: 'Checkin',
              dataIndex: 'totalCheckin'
            },
            {
              title: 'Checkout',
              dataIndex: 'totalCheckout'
            },
            {
              title: 'Bảo vệ',
              dataIndex: 'totalSecurity'
            }
          ]
        },
        {
          title: `Hôm nay(${date.format('DD/MM/YYYY')})`,
          children: [
            {
              title: 'Stylist',
              dataIndex: 'totalStylistToday'
            },
            {
              title: 'Skinner',
              dataIndex: 'totalSkinnerToday'
            }
          ]
        },
        {
          title: `Ngày mai(${date.add('days', 1).format('DD/MM/YYYY')})`,
          children: [
            {
              title: 'Stylist',
              dataIndex: 'totalStylistTomorrow'
            },
            {
              title: 'Skinner',
              dataIndex: 'totalSkinnerTomorrow'
            }
          ]
        },
        {
          title: `Ngày kia(${date.add('days', 1).format('DD/MM/YYYY')})`,
          children: [
            {
              title: 'Stylist',
              dataIndex: 'totalStylistAfterTomorrow'
            },
            {
              title: 'Skinner',
              dataIndex: 'totalSkinnerAfterTomorrow'
            }
          ]
        }
      ]
      return columns
    }
  },
  components: { Pagination, CTable },
  watch: {
    workDate: function(value) {
      if (!value) {
        this.dataSource = []
        this.total = 0
      }
    }
  },
  data() {
    return {
      workDate: moment(),
      queryParam: {},
      dataSource: [],
      loading: false,
      total: 0
    }
  },
  methods: {
    moment,
    async getData() {
      try {
        if (!this.workDate) {
          this.$message.error('Vui lòng chọn ngày để xem.')
          return
        }
        this.loading = true
        this.dataSource = []
        this.total = 0
        this.queryParam.workDate = this.workDate.format('DD/MM/YYYY')
        const res = await TimekeepingReportRepository.get(this.queryParam)
        this.dataSource = res.results
        this.total = res.total
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    resetFilter() {
      this.queryParam.salonId = '0'
      this.queryParam.workDate = ''
      this.workDate = moment()
    },
    onChangeDate(date, dateString) {
      this.queryParam.workDate = dateString
      this.workDate = date
    },
    filterOptionSalon(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>
