import Table from 'ant-design-vue/es/table/Table'
import './index.css'

export default {
  props: Object.assign({}, Table.props, {
    dataSource: {
      type: Array,
      required: true
    }
  }),
  methods: {
    setStyle(row, key) {
      let className = ''
      if (row.totalStylist > 16) {
        className += ' stylist-light-green'
      }
      if (row.totalStylist / (row.totalSkinner === 0 ? 1 : row.totalSkinner) > 2) {
        className += ' skinner-light-pink'
      }
      if (row.totalSecurity > 2) {
        className += ' security-light-green'
      } else if (row.totalSecurity < 2) {
        className += ' security-light-pink'
      }
      if (row.totalCheckin > 2) {
        className += ' checkin-light-green'
      } else if (row.totalCheckin < 2) {
        className += ' checkin-light-pink'
      }
      if (row.totalCheckout > 2) {
        className += ' checkout-light-green'
      } else if (row.totalCheckout < 2) {
        className += ' checkout-light-pink'
      }

      const today = row.totalStylistToday / (!row.totalSkinnerToday ? 1 : row.totalSkinnerToday)
      if (today > 2) {
        className += ' today-green'
      } else if (today < 2) {
        className += ' today-pink'
      }
      const tomorrow = row.totalStylistTomorrow / (!row.totalSkinnerTomorrow ? 1 : row.totalSkinnerTomorrow)
      if (tomorrow > 2) {
        className += ' tomorrow-green'
      } else if (tomorrow < 2) {
        className += ' tomorrow-pink'
      }

      const afterTomorrow =
        row.totalStylistAfterTomorrow / (!row.totalSkinnerAfterTomorrow ? 1 : row.totalSkinnerAfterTomorrow)
      if (afterTomorrow > 2) {
        className += ' after-tomorrow-green'
      } else if (afterTomorrow < 2) {
        className += ' after-tomorrow-pink'
      }

      return className
    }
  },
  render() {
    let table = (
      <Table
        columns={this.columns}
        dataSource={this.dataSource}
        pagination={false}
        bordered
        loading={this.loading}
        rowClassName={this.setStyle}
      />
    )
    return <div>{table}</div>
  }
}
